import { type BuilderConfigSchema, type SchemaTypeOf } from 'common';

export function createElements(): {
  container: HTMLDivElement;
  iframe: HTMLIFrameElement;
} {
  const container = document.createElement('div');
  Object.assign(container.style, {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    boxSizing: 'border-box',
  });

  const iframe = document.createElement('iframe');
  iframe.hidden = false;
  iframe.allow = 'camera;';
  Object.assign(iframe.style, {
    width: '100%',
    height: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    border: 'none',
  });

  container.append(iframe);

  return {
    container,
    iframe,
  };
}

export function createInterface(
  config: SchemaTypeOf<typeof BuilderConfigSchema>,
  {
    container,
    iframe,
  }: { container: HTMLDivElement; iframe: HTMLIFrameElement },
  hideFrame: boolean,
): void {
  if (hideFrame) {
    container.style.display = 'none';
    iframe.style.display = 'none';
  }

  iframe.src = `${config.url}/index.html`;

  const windowMessageListener = (ev: MessageEvent<any>): void => {
    if (ev?.data?.subject === 'SDK.Ready') {
      iframe.contentWindow?.postMessage(
        {
          subject: 'SDK.Config',
          data: config,
        },
        '*',
      );
      window.removeEventListener('message', windowMessageListener);
    }
  };
  window.addEventListener('message', windowMessageListener);

  document.body.insertAdjacentElement('afterend', container);
}

export function removeInterface({
  container,
  iframe,
}: {
  container: HTMLDivElement;
  iframe: HTMLIFrameElement;
}): void {
  iframe.remove();
  container.remove();
}
