import {
  defaultConfig,
  type SchemaTypeOf,
  type CompressionAlgorithmSchema,
  type ScreenOrientationSchema,
  type CredentialSchema,
  type InstructionOptionsSchema,
  ProxyMiddlewareSchema,
  UrlSchema,
  CameraConfigSchema,
  PartialLivenessContentConfigurationSchema,
  PartialLivenessThemeConfigurationSchema,
  type BuilderConfigSchema,
  type InstructionSchema,
} from 'common';
import { createElements, createInterface, removeInterface } from './interface';

// type MessageCallback = (this: Window, ev: MessageEvent<any>) => any;

/**
 * Liveness SDK interface
 */
class Builder {
  private readonly config: SchemaTypeOf<typeof BuilderConfigSchema>;

  constructor() {
    this.config = defaultConfig;
  }

  /**
   * Sets the text content of the SDK to the desired text. Usually used for translation.
   * @param content the text content to replace
   * @returns this builder
   */
  public setContent(
    content: SchemaTypeOf<typeof PartialLivenessContentConfigurationSchema>,
  ): Builder {
    this.config.content =
      PartialLivenessContentConfigurationSchema.parse(content);
    return this;
  }

  /**
   * Sets the compression algorithm parameter.
   * @param algorithm the compression parameter
   * @returns this builder
   */
  public setCompressionAlgorithm(
    algorithm: SchemaTypeOf<typeof CompressionAlgorithmSchema>,
  ): Builder {
    this.config.metadata = {
      ...this.config.metadata,
      CompressionAlgorithm: {
        ...this.config.metadata.CompressionAlgorithm,
        ...algorithm,
      },
    };
    return this;
  }

  /**
   * Sets the preferred orientation to be forced when capturing photo.
   * @param orientation the preferred orientation
   * @returns this builder
   */
  public setScreenOrientation(
    orientation: SchemaTypeOf<typeof ScreenOrientationSchema>,
  ): Builder {
    this.config.metadata = {
      ...this.config.metadata,
      ScreenOrientation: orientation,
    };
    return this;
  }

  // /**
  //  * Sets the waiting time to capture photo.
  //  * @param value the waiting time to capture photo
  //  * @returns this builder
  //  */
  // public setWaitingTime(value: number): Builder {
  //   this.config.metadata = {
  //     ...this.config.metadata,
  //     WaitingTime: value,
  //   };
  //   return this;
  // }

  /**
   * Sets the credential to generate image signature for authenticity checking
   * @param credential credential used to generate image signature
   * @returns this builder
   */
  public setCredential(
    credential: SchemaTypeOf<typeof CredentialSchema>,
  ): Builder {
    this.config.credential = credential;
    return this;
  }

  /**
   * Duration from the start of show camera preview till disrupt detection enabled.
   * @param duration the timeout duration from the start of show camera preview
   * @returns this builder
   */
  public setDisruptDuration(duration: number): Builder {
    this.config.disruptDuration = duration;
    return this;
  }

  /**
   * Sets the instruction configuration
   * @param instructions an array of instructions made available to appear in instruction randomization
   * @param options more instruction parameters
   * @returns this builder
   */
  public setInstruction(
    instructions: Array<SchemaTypeOf<typeof InstructionSchema>>,
    options?: Partial<
      Omit<SchemaTypeOf<typeof InstructionOptionsSchema>, 'seeds'>
    >,
  ): Builder {
    this.config.instruction.seeds = instructions;
    if (options !== undefined) {
      const { commands, illustrator, seedLimit, translator, isShowInstruction, isSoundLooping, isUseSound, speaker } =
        this.config.instruction;

      this.config.instruction.commands = options.commands ?? commands;
      this.config.instruction.seedLimit = options.seedLimit ?? seedLimit;
      this.config.instruction.isShowInstruction = options.isShowInstruction ?? isShowInstruction;
      this.config.instruction.isSoundLooping = options.isSoundLooping ?? isSoundLooping;
      this.config.instruction.isUseSound = options.isUseSound ?? isUseSound;
      this.config.instruction.translator = {
        ...translator,
        ...options.translator,
      };
      this.config.instruction.illustrator = {
        ...illustrator,
        ...options.illustrator,
      };
      this.config.instruction.speaker = {
        ...speaker,
        ...options.speaker
      }
    }
    return this;
  }

  /**
   * Sets the endpoint url proxy collection to be used for the SDK
   * @param proxy endpoint proxy collection to be used
   * @returns this builder
   */
  public setProxyMiddleware(
    proxy: SchemaTypeOf<typeof ProxyMiddlewareSchema>,
  ): Builder {
    this.config.proxy = ProxyMiddlewareSchema.parse(proxy);
    return this;
  }

  /**
   * Sets the timeout duration from the start of verification page appearance. After this timeout expires, the SDK will throw `Verification.Timeout` message.
   * @param duration the timeout duration from the start of verification page appearance
   * @returns this builder
   */
  public setTimeout(duration: number): Builder {
    this.config.timeout = duration;
    return this;
  }

  /**
   * Sets the theme configuration for the SDK
   * @param theme theme configurations
   * @returns this builder
   */
  public setTheme(
    theme: SchemaTypeOf<typeof PartialLivenessThemeConfigurationSchema>,
  ): Builder {
    this.config.theme = PartialLivenessThemeConfigurationSchema.parse(theme);
    return this;
  }

  /**
   * Sets the SDK base path for starting the SDK
   * @param url the SDK base path
   * @returns this builder
   */
  public setURL(url: SchemaTypeOf<typeof UrlSchema>): Builder {
    this.config.url = UrlSchema.parse(url);
    return this;
  }

  /**
   * Sets array of strings to be checked if any of the camera labels has the substring of any of the specified labels
   * @param labels array of strings to be checked
   * @returns this builder
   */
  public setVirtualCameraLabel(labels: string[]): Builder {
    this.config.camera = CameraConfigSchema.parse({ virtualLabel: labels });
    return this;
  }

  /**
   * Builds the SDK interface that can be started and destroyed
   * @returns the SDK interface
   */
  public build(): {
    config: SchemaTypeOf<typeof BuilderConfigSchema>;
    elements: { container: HTMLDivElement; iframe: HTMLIFrameElement };
    onStart: (hideFrame?: boolean) => void;
    onDestroy: () => void;
  } {
    return {
      config: this.config,
      elements: createElements(),
      onStart(hideFrame: boolean = false) {
        createInterface(this.config, this.elements, hideFrame);
      },
      onDestroy() {
        removeInterface(this.elements);
      },
    };
  }

  /**
   * Inspects the passed configuration for debugging
   */
  public inspect(): void {
    console.dir('[Inspector]', this.config);
  }

  /* Better interface? */
  // private listeners: MessageCallback[] = [];
  // public on(subject: "success", callback: (data: any) => void): void;
  // public on(subject: "notAllowed", callback: () => void): void;
  // public on(subject: "notFound", callback: () => void): void;
  // public on(subject: string, callback: (...data: any) => void): void {
  //   if (subject === "success") {
  //     const listener: MessageCallback = ({ data: { subject, data } }) => {
  //       if (subject === "Verification.Success") {
  //         callback(data);
  //       }
  //     };
  //     this.listeners.push(listener);
  //     window.addEventListener("message", listener);
  //     return;
  //   }

  //   if (subject === "notAllowed") {
  //     const listener: MessageCallback = ({ data: { subject } }) => {
  //       if (subject === "Camera.NotAllowed") {
  //         callback();
  //       }
  //     };
  //     this.listeners.push(listener);
  //     window.addEventListener("message", listener);
  //     return;
  //   }

  //   if (subject === "notFound") {
  //     const listener: MessageCallback = ({ data: { subject } }) => {
  //       if (subject === "Camera.NotFound") {
  //         callback();
  //       }
  //     };
  //     this.listeners.push(listener);
  //     window.addEventListener("message", listener);
  //     return;
  //   }
  // }

  // public off() {
  //   this.listeners.forEach((listener) =>
  //     window.removeEventListener("message", listener),
  //   );
  //   this.listeners.splice(0, this.listeners.length);
  // }
}

export default Builder;
